import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { Link as RemixLink } from "@remix-run/react";
import { AppUrl } from "@tamarack/shared/AppUrl";
import { useFilterValuesState } from "./hooks/useFilterValuesState";

export type LinkProps = Omit<RemixLinkProps, "to"> & {
  to: AppUrl | string;
};

export default function Link({ to, ...linkProps }: LinkProps) {
  const pathname = typeof to === "string" ? to : to.pathname;
  const defaultSearchParams = new AppUrl(pathname).searchParams;
  const { getFilterValue } = useFilterValuesState(pathname);
  let _to = to;

  if (typeof to !== "string") {
    const pageFilterValues = getFilterValue();
    _to = new AppUrl(to, defaultSearchParams).mergeSearchParamsFromObject(pageFilterValues);
  }

  return <RemixLink to={_to.toString()} prefetch="intent" {...linkProps} />;
}
